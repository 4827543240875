<template>
  <div id="Aluno_Meus_Talentos" class="pl-1 position-relative">
    <ModalConclusaoExercicio />
    <Loader v-if="showLoader" />
    <ModalBemVindo
      v-if="primeiraVezNoTema"
      :propTitleTema="dadosExercicioAluno.name"
      :play="true"
    />
    <ModalListaExercicios :exercicios="getListaExerciciosModal" />

    <stockThinking
      class="stock-thinking" 
      v-if="($store.getters.getStockThinking || $store.getters.getBloquearTabuleiro) && $route.path.match('continuar-exercicio')" 
    />
    
    <div class="here bg-edf0f5 py-5 position-relative">
      <div class="container in-here bg-fff shadow-sm">
        <div class="row pb-5 pt-4 px-5">
          <div class="col-md-12 px-0">
            <div class="row">
              <div
                v-if="$store.getters.getPerfil != 2"
                class="col-md-3 d-flex align-items-center justify-content-start"
              >
                <!-- <button class="btn-style-default-less bg-0e5caf color-fff" v-if="getUserDatas.profile_id == 1 || getUserDatas.profile_id == 3 || getUserDatas.profile_id == 4" @click="listaExerciciosAssiste()">Lista de Exercícios</button>               -->
                <button @click="pathTemasExercicios()"
                  class="btn-style-default-less bg-0e5caf color-fff mr-2 text-nowrap"
                >                  
                  Temas e Exercícios
                </button>

                <button @click="showModal('modalNameModalListaExercicios')"
                  class="btn-style-default-less bg-0e5caf color-fff text-nowrap"
                >                  
                  Lista Exercícios
                </button>
              </div>
            </div>
            <div
              class="nav-prev-next d-flex justify-content-between py-3 position-relative mb-5"
            >
              <!-- <div v-if="$route.params.indiceStore != 1 && getUserDatas.profile_id == 5" :class="`d-flex align-items-center justify-content-center ${verificaPaginacao && !tentativasOk ? 'opacity-more' : ''}`">
                <button @click="ercicio('anterior')" class="bg-none px-0" :disabled="$route.params.indiceStore == 0 ? true : false">
                  <div
                    class="icon-prev shape-30-30 bg-0e5caf d-flex align-items-center justify-content-center"
                  >
                    <img src="@/assets/aluno/iconPrevNext.png" alt="iconPrevNext.png" />
                  </div>
                </button>
                <div>
                  <h6>Exercício Anterior</h6>
                </div>
              </div> -->
              <div
                id="labelExercicios"
                class="d-flex align-items-center justify-content-center position-absolute w-100"
                style="z-index: 1"
              >
                <h2 @click="showModal('modalNameModalListaExercicios')">
                  <sup>(CÓD {{ dadosExercicioAluno.cod }})</sup>
                  {{ dadosExercicioAluno.name }}
                </h2>
              </div>
              <!-- <div
                v-if="$route.params.indiceStore < getDadosExercicioPaginacao.length && getUserDatas.profile_id == 5"
                :class="`d-flex align-items-center justify-content-center`"
              >
                <div>
                  <h6>Próximo Exercício</h6>
                </div>
                <button @click="ercicio('proximo')" class="bg-none px-0" :disabled="!tentativasOk" ><div
                    class="icon-next shape-30-30 bg-0e5caf d-flex align-items-center justify-content-center"
                  >
                    <img src="@/assets/aluno/iconPrevNext.png" alt="iconPrevNext.png" />
                  </div>
                </button>
              </div> -->
            </div>
          </div>
          <div class="col-md-7 text-center bg-fff pl-0">
            <div
              class="tabuleiro position-relative"
              v-if="codigoFenExercicioAluno && $store.getters.getPerfil == 5"
            >
              <theTabuleiro v-if="typeExercicio == 2" />
              <div>
                <!--<theTabuleiroPGNaluno :alunofen="codigoFenExercicioAluno" />-->
                <component
                  :is="atualComponente"
                  @enviaCoordenadaPeca="recebeCoordenadaPeca"
                  @criaBottaoJogada="criarBotaoJogadas"
                  @verificaTentativas="verificaTentativas"
                  :pecaAluno="pecaAluno"
                  :theFEN="codigoFenExercicioAluno"
                  :dadosExercicioAluno="dadosExercicioAluno"
                  :dadosExercicios="dadosExercicios"
                />
              </div>
            </div>
            <div v-else>
              <theTabuleiroAssisteJogadas
                :dadosExercicioAluno="dadosExercicioAluno"
                @tranfereDadosDasTentativas="recebeDadosDasTentativas"
              />
            </div>
          </div>
          <div class="col-md-5 pr-0 mt-5 coluna-info">
            <h3>Planilha</h3>
            <div class="row">
              <div class="col-md-6 mt-3 ml-3 text-center">
                <h3>
                  {{
                    dadosExercicioAluno.cor == "white" ? alunoNameToUppercase(
                      getPerfil == 5 ? getUserDatas.name : $route.params.nameAluno
                    )  : "Skaki"
                  }}
                </h3>
              </div>
              <div class="col-md-4 mt-3 text-center">
                <h3>
                  {{
                    dadosExercicioAluno.cor == "white" ? "Skaki" : alunoNameToUppercase(
                      getPerfil == 5 ? getUserDatas.name : $route.params.nameAluno
                    )
                  }}
                </h3>
              </div>
            </div>
            <div id="thePlanilha" class="row scrollable jogadas" style="min-height: 125px">

               <!-- <div class="col-md-1 mt-3">
                 <div id="rodadas-planilha" class="row">
                   
                 </div>
               </div> -->

               <div class="col-md-1 p-0 mt-3 text-center">
                <div
                  class="row"
                  id="rodadas-planilha"
                >
                </div>
              </div>

              <div class="col-md-5 mt-3 text-center">
                <div
                  class="row"
                  :id="
                    dadosExercicioAluno.cor == 'white'
                      ? 'jogadaAluno'
                      : 'jogadaMaquina'
                  "
                >
                  <button
                    v-if="dadosExercicioAluno.cor != 'white'"
                    class="col-md-7 mx-auto"
                    style="background-color: rgb(250, 250, 250); margin-top: 2px; width: 60px; height: 30px;"
                  >
                    (...)
                  </button>
                </div>
              </div>
              <div class="col-md-5 mt-3 text-center">
                <div
                  class="row"
                  :id="
                    dadosExercicioAluno.cor == 'white'
                      ? 'jogadaMaquina'
                      : 'jogadaAluno'
                  "
                ></div>
              </div>
            </div>
            <!-- <div
              class="border-default shadow-sm scrollable shape-historico-dica"
            >
              <div
                class="border-default pr-4 pl-4 pb-4 pt-2 scrollable shape-historico-dica shadow"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="row position-relative">
                      <div
                        class="col-md-8 mx-auto justify-content-between where-historico-de-jogadas-aluno position-relative"
                      >
                        <div class="jogada d-flex justify-content-between pt-3">
                          <div
                            :id="
                              dadosExercicioAluno.cor == 'white'
                                ? 'coordenadaPecaAluno'
                                : 'coordenadaPecaProfessor'
                            "
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row position-relative">
                      <div
                        class="col-md-8 mx-auto justify-content-between where-historico-de-jogadas-professor"
                      >
                        <div class="jogada d-flex justify-content-between pt-3">
                          <div
                            :id="
                              dadosExercicioAluno.cor == 'white'
                                ? 'coordenadaPecaProfessor'
                                : 'coordenadaPecaAluno'
                            "
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div
              v-if="$store.getters.getPerfil == 5"
              class="border-default shadow-sm p-4 mt-4 shape-historico-dica scrollable"
            >
              <h3>Dicas:</h3>
              <p class="mt-4">
                {{
                  dadosExercicios.dica
                    ? dadosExercicios.dica
                    : `Lembre-se sempre do que aprendeu. Você é capaz! Bom jogo!`
                }}
              </p>
            </div>
            <div
              v-if="$store.getters.getPerfil == 5"
              class="border-default shadow-sm p-4 mt-4 shape-recomecar scrollable d-flex align-items-center justify-content-center"
            >
              <div
                class="col-md-12 mx-auto d-flex align-items-center justify-content-center justify-content-between"
              >
                <div class="d-flex">
                  <img :src="dadosExercicioAluno.cor == 'white' ? wk : bk" alt="sssss">
                </div>
                <div>
                  <div class="d-flex align-items-center justify-content-center">
                    <button class="bg-none" @click="recomecar()">
                      <div
                        class="shape mb-4 bg-0e5caf d-flex align-items-center justify-content-center"
                      >
                        <img
                          src="@/assets/aluno/iconResetGame.png"
                          alt="iconResetGame.png"
                        />
                      </div>
                    </button>
                  </div>
                  <h4>Recomeçar</h4>
                </div>
                <div
                  :class="
                   $route.params.indiceExercicio == Number(getPaginacao.length) ? 'opacity-more' : getExercicioConcluido ? '' : getTentativasExercicios == false ? 'opacity-more' : ''
                  "
                >
                  <div
                    :class="
                      `d-flex align-items-center justify-content-center ${
                        $route.params.indiceExercicio == Number(getPaginacao.length) ? 'opacity-more' : getExercicioConcluido ? '' : getTentativasExercicios == false ? 'opacity-more' : ''
                      }`
                    "
                  >
                    <button
                      class="bg-none"
                      @click="pularPagina('proximo')"
                      :disabled="$route.params.indiceExercicio == Number(getPaginacao.length) ? true : getExercicioConcluido ? false : getTentativasExercicios == false ? true : false"
                    >
                      <div
                        class="icon-next mb-4 shape bg-0e5caf d-flex align-items-center justify-content-center"
                      >
                        <img
                          src="@/assets/aluno/iconPrevNext.png"
                          alt="iconPrevNext.png"
                        />
                      </div>
                    </button>
                  </div>  
                  <h4>Pular</h4>
                </div>
              </div>
            </div>
            <div
              class="col-md-12 mx-auto mt-4"
              v-if="$store.getters.getPerfil != 5"
            >
              <div class="row">
                <div
                  :class="`col-md-4 ${contLance <= -1 ? 'opacity-more' : ''}`"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <div
                      class="shape mb-4 bg-0e5caf d-flex align-items-center justify-content-center"
                    >
                      <button
                        class="bg-none"
                        @click="avancaRetornaLance('Anterior')"
                        :disabled="contLance == -1 ? true : false"
                      >
                        <img
                          src="@/assets/aluno/iconPrevNext.png"
                          alt="iconPrevNext.png"
                        />
                      </button>
                    </div>
                  </div>
                  <h4>
                    Jogada <br />
                    Anterior
                  </h4>
                </div>
                <div
                  :class="`col-md-4 ${contLance <= -1 ? 'opacity-more' : ''}`"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <div
                      class="shape mb-4 bg-0e5caf d-flex align-items-center justify-content-center"
                    >
                      <button
                        class="bg-none"
                        @click="recomecar()"
                        :disabled="contLance <= -1 ? true : false"
                      >
                        <img
                          src="@/assets/aluno/iconResetGame.png"
                          alt="iconResetGame.png"
                        />
                      </button>
                    </div>
                  </div>
                  <h4>Recomeçar</h4>
                </div>
                <div
                  :class="
                    `col-md-4 ${
                      contLance >= Number(fenDaTentativa.length - 1)
                        ? 'opacity-more'
                        : ''
                    }`
                  "
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <div
                      class="icon-next mb-4 shape bg-0e5caf d-flex align-items-center justify-content-center"
                    >
                      <button
                        class="bg-none"
                        @click="avancaRetornaLance('proxima')"
                        :disabled="
                          contLance >= Number(fenDaTentativa.length - 1)
                            ? true
                            : false
                        "
                      >
                        <img
                          src="@/assets/aluno/iconPrevNext.png"
                          alt="iconPrevNext.png"
                        />
                      </button>
                    </div>
                  </div>
                  <h4>Próxima Jogada</h4>
                </div>
              </div>
            </div>
            <div
              v-if="
                $store.getters.getPerfil == 3 ||
                  $store.getters.getPerfil == 4 ||
                  $store.getters.getPerfil == 1
              "
              class="border-default shadow-sm p-4 mt-4 shape-historico-dica"
            >
              <div>
                <h3>
                  Tentativas neste Exercício:
                  {{
                    dadosOficiaisExercicios
                      ? dadosOficiaisExercicios.user_tentativa.length
                      : ""
                  }}
                </h3>
                <h5 class="py-3 f-w-500">
                  Visualizando <br />
                  tentativa número:
                </h5>
              </div>
              <div class="d-flex justify-content-between">
                <div
                  :class="
                    `${$route.params.indiceStore != 1 ? '' : 'opacity-more'}`
                  "
                >
                  <div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <div
                        class="icon-prev shape-30-30 bg-0e5caf d-flex align-items-center justify-content-center"
                      >
                        <button
                          @click="avancaRetornaTentativa()"
                          class="bg-none"
                          :disabled="
                            dadosOficiaisExercicios ? valida('anterior') : false
                          "
                        >
                          <img
                            src="@/assets/aluno/iconPrevNext.png"
                            alt="iconPrevNext.png"
                          />
                        </button>
                      </div>
                    </div>
                    <p class="tentativas mt-2">Tentativa Anterior</p>
                  </div>
                </div>
                <div class="mt-2 d-flex justify-content-between">
                  <div class="small mt-1" v-if="$route.params.indiceStore != 1">
                    <p>
                      {{
                        $route.params.indiceStore == 0 ||
                        $route.params.indiceStore == 1
                          ? 1
                          : $route.params.indiceStore - 1
                      }}
                    </p>
                  </div>
                  <div class="mx-4 mt-1">
                    <p class="color-fff shape-18-18 bg-42cc7e">
                      {{
                        $route.params.indiceStore == 0
                          ? 1
                          : $route.params.indiceStore
                      }}
                    </p>
                  </div>
                  <div class="small mt-1" v-if="!valida('proximo')">
                    <p>{{ Number($route.params.indiceStore) + 1 }}</p>
                  </div>
                </div>
                <div>
                  <div :class="`${!valida('proximo') ? '' : 'opacity-more'}`">
                    <div
                      :class="
                        `${
                          dadosOficiaisExercicios
                            ? valida('proximo')
                            : 'opacity-more'
                        }`
                      "
                    >
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <div
                          :class="
                            `icon-next shape-30-30 bg-0e5caf d-flex align-items-center justify-content-center`
                          "
                        >
                          <button
                            :disabled="
                              dadosOficiaisExercicios
                                ? valida('proximo')
                                : false
                            "
                            class="bg-none"
                            @click="avancaRetornaTentativa('próximo')"
                          >
                            <img
                              src="@/assets/aluno/iconPrevNext.png"
                              alt="iconPrevNext.png"
                            />
                          </button>
                        </div>
                      </div>
                      <p class="tentativas mt-2">Próxima Tentativa</p>
                      <img
                        src="@/assets/aluno/iconPrevNext.png"
                        alt="iconPrevNext.png"
                      />
                    </div>
                  </div>
                </div>                
              </div>
              <div class="d-flex j-c-center">
                  <input type="number" style="width: 50px" :max="tentativas" min="1" v-model="especifiAttempt" @keyup.enter="goToAttempt()">
                  <button @click="goToAttempt()" class="shape-30-30 ml-2 color-fff bg-0e5caf"><i class="fas fa-arrow-right" ></i></button>
                </div>
            </div>
            <div
              class="border-default shadow-sm p-4 mt-4 shape-recomecar scrollable d-flex align-items-center justify-content-center"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalBemVindo from "@/components/modais/ModalBemVindo";
import ModalConclusaoExercicio from "@/components/modais/ModalConclusaoExercicio";
import coordenadas from "@/mixins/coordenadas";
import theTabuleiro from "@/components/theTabuleiro";
import theTabuleiroPGNaluno from "@/components/theTabuleiroPGNaluno";
import theTabuleiroAssisteJogadas from "@/components/theTabuleiroAssisteJogadas";
import Loader from "@/components/Loader";
import stockThinking from "@/components/stockThinking";
import ModalListaExercicios from "@/components/modais/ModalListaExercicios";
import $ from "jquery";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      contadorRodada: 0,
      contadorNumeroColuna: 0,
      indiceStore: Number(this.$route.params.indiceStore),
      dadosOficiaisExercicios: null,
      mainFEN: null,
      primeiraVezNoTema: true,
      typeExercicio: 2,
      atualComponente: null,
      codigoFenExercicioAluno: null,
      dadosExercicios: null,
      showLoader: true,
      tentativasOk: false,
      getPaginacao: this.$store.getters.getDadosExercicioPaginacao,
      verificaPaginacao: this.$route.params.indiceStore == 0 ? true : false,
      fenDaTentativa: [],
      theBoard: null,
      contLance: 0,
      especifiAttempt: 1,
      tentativas: 0,
      bk: require("@/assets/bK.png"),
      wk: require('@/assets/wK.png'),
    };
  },
  mixins: [coordenadas],
  components: {
    ModalBemVindo,
    theTabuleiro,
    theTabuleiroPGNaluno,
    Loader,
    ModalConclusaoExercicio,
    stockThinking,
    theTabuleiroAssisteJogadas,
    ModalListaExercicios,
  },
  props: ["pecaDoAluno", "getUserDatas"],
  watch: {
    $route(to, from) {
      setTimeout(() => {
      // location.reload()
      this.contLance = -1;
      this.fenTentativa();
      this.theBoard.position(this.mainFEN);

      document.querySelectorAll('.jogada').forEach(function(a){
        a.remove()
      })
      this.criarBotaoJogadasPlanilha()
      }, 500);    
    },
    dadosOficiaisExercicios(){
      if(this.dadosOficiaisExercicios !== null) {
        this.fenTentativa();  
        this.returnTentativas();
        this.criarBotaoJogadasPlanilha()
      }
    }
  },
  mounted(){
    var that = this
    document.addEventListener('keydown', function(e) {
      var sentido = ''
      switch (e.keyCode) {
        case 36:
          that.recomecar()
        break;
        case 37:
          sentido = 'anterior'
        break;
        // case 38:
        //   alert('up');
        // break;
        case 39:
          sentido = 'proxima'
        break;
        // case 40:
        //   alert('down');
        // break;
      }
      that.avancaRetornaLance(sentido)
    });
  },
  computed: {
    ...mapGetters(["getListaExerciciosModal", "getDadosExercicioPaginacao", "getPerfil", "getTentativasExercicios", "getExercicioConcluido"]),
  },
  created() {
    if(localStorage.getItem("pageExercicios") === null) {
      localStorage.setItem("pageExercicios", this.indiceStore)
    }

    this.$store.commit("SET_STOCK_THINK", false);
    this.showModal("modalNamemodalBemVindo");
    this.getTemasExercicios();
    this.showModal("modalConclusaoExercicio");
    setTimeout(() => {
      this.contLance = -1;
    }, 1500);   
  },
  methods: {

    pathTemasExercicios() {
      this.$router.push({path: this.$store.getters.getPerfil === 5 ? '/aluno/inicial' : `/administrador/unidades-e-instituicoes/${
        this.$route.params.idunidade}/${
          this.$route.params.unidade}/${
            this.$route.params.idclass}/${
              this.$route.params.class}/statusAluno/${
                this.$route.params.idAluno}/${
                  this.$route.params.nameAluno}`})

    },

    criarColunaRodas(){
      var colRodadas = document.createElement("div")
      var spanRodadas = document.createElement("span")
      var paiColRodadas = document.getElementById("rodadas-planilha")
      
      colRodadas.appendChild(spanRodadas)
      paiColRodadas.appendChild(colRodadas)

      colRodadas.setAttribute("class", "contador-jogadas col-md-12 p-0 d-flex justify-content-end align-items-center");
      spanRodadas.setAttribute("class", "align-center font-weight-bold")

      colRodadas.style.backgroundColor = "#fff";
      colRodadas.style.height = "32px";
      this.contadorNumeroColuna += 1
      spanRodadas.appendChild(document.createTextNode(`${this.contadorNumeroColuna}`))
    },

    criarBotaoJogadas(objJogada) { 
      this.contadorRodada += 1 
      var elemento_pai = document.getElementById(
        objJogada.turnAluno ? "jogadaAluno" : "jogadaMaquina"
      );
      var elemento = document.createElement("button");
      var texto = document.createTextNode(
        objJogada.peca ? objJogada.peca : "-"
      );
      elemento.style.backgroundColor = "rgb(250,250,250)";
      elemento.style.marginTop = "2px";
      elemento.style.width = "60px";
      elemento.style.height = "30px";

      if(this.dadosExercicioAluno.cor == 'black') {
        var criarRodadaPreto = (this.contadorRodada + 1) / 2 > this.contadorNumeroColuna
        if(this.contadorRodada == 1 || criarRodadaPreto ) {
          this.criarColunaRodas()
        }
      }else {
        var criarRodadaBranca = this.contadorRodada / 2 > this.contadorNumeroColuna
        if(this.contadorRodada == 1 || criarRodadaBranca ) {
          this.criarColunaRodas()
        }
      }

      
      // this.count += 1
      // if(this.count % 2 == 0){
      //   var elemento_pai_count = document.getElementById(
      //     this.dadosExercicioAluno.cor == 'white' ? 'jogadaAluno' : 'jogadaMaquina'
      //   )
      //   var elementoCountJogada = document.createElement("span")
        
      //   elementoCountJogada.appendChild(elementoCountJogadaTexto);
      //   elemento_pai_count.appendChild(elementoCountJogada);
      // }

      elemento.appendChild(texto);
      elemento.setAttribute("class", "jogada col-md-7 mx-auto");
      elemento.setAttribute("id", objJogada.objJogadaId);
      elemento.onclick = function() {
        this.theBoard.position(objJogada.fen);
      };
      if(!objJogada.peca){
         elemento.setAttribute("class", "d-none");
      }

      elemento_pai.appendChild(elemento);
    },
    criarBotaoJogadasPlanilha(){      
      this.dadosOficiaisExercicios.user_tentativa.forEach((element, index) => {
        if(index + 1 == this.$route.params.indiceStore){
          this.tentativaPgn = element.pgn.split(',')
          this.tentativaPgn.filter(arr => arr != "").forEach((element2, index2) => {
            let objJogada = {
              turnAluno: index2%2 == 0 ? true : false,
              peca: element2,
              fen: element.fen.split(',')[index2],
              objJogadaId: `jogada_${index2}`
            }
            this.criarBotaoJogadas(objJogada)
          });
        }
      });
    },
    getTemasExercicios() {
      $.ajax({
        type: "GET",
        url: `${this.VUE_APP_CMS}api/exercicios/${this.$route.params.idExercicio}`,
        data: null,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.dadosExercicioAluno = response;
          let fens = response.resolucao.split(",");
          this.dadosExercicios = response;
          this.codigoFenExercicioAluno = `${fens[0]} ${
            response.cor.match("w") ? "w" : "b"
          } KQkq - 0 1`;
          this.atualComponente =
            response.exercicio_categoria_id == 1
              ? "theTabuleiroPGNaluno"
              : "theTabuleiro";
          this.typeExercicio = response.exercicio_categoria_id;
          this.showLoader = false;
          this.toastGlobal("success", "Exercício carregado com sucesso");
        },
        error: (response) => {
          this.toastGlobal(
            "error",
            "Algo de errado ocorreu ao carregar o exercício"
          );
        },
      });
    },
    verificaTentativas(val) {
      this.tentativasOk = val;
    },
    recomecar() {
      if (this.getUserDatas.profile_id == 5) location.reload();
      else {
        this.contLance = -1;
        this.theBoard.position(this.dadosExercicioAluno.fen);
      }
    },
    pularPagina(sentido) {
      let rota = ''
      
      rota = `/aluno/continuar-exercicio/${
        Number(this.$route.params.temaGrupoExercicio) + 1}/${
        this.$route.params.temaExercicio}/${
        Number(this.$route.params.idExercicio) + 1}/${
        this.$route.params.indiceStore}/${
        Number(this.$route.params.indiceExercicio) + 1}`

      setTimeout(() => {
        this.$router.push(rota);
      }, 200);
    },
    recebeDadosDasTentativas(val) {
      this.dadosOficiaisExercicios = val.dados;
      this.theBoard = val.mainBoard;
      this.fenDaTentativa =
        val.dados.user_tentativa[this.$route.params.indiceStore];
      this.mainFEN = val.mainFEN;

    },
    avancaRetornaTentativa(val) {
      let cont = 0;
      let url = this.$route.path.split("/");
      url = url.splice(0, Number(url.length) - 1);

      if (val) cont = Number(this.$route.params.indiceStore) + 1;
      else cont = Number(this.$route.params.indiceStore) - 1;
      this.$router.push(`${url.toString().replaceAll(",", "/")}/${cont}`);
    },
    valida(val) {
      if (val == "proximo" && this.dadosOficiaisExercicios)
        return (
          this.dadosOficiaisExercicios.user_tentativa.length ==
          this.$route.params.indiceStore
        );
      else return this.$route.params.indiceStore < 2;
    },
    returnTentativas(){
      setTimeout(() => {
        this.tentativas = this.dadosOficiaisExercicios.user_tentativa.length
      }, 1000);
    },
    fenTentativa() {
      this.fenDaTentativa = this.dadosOficiaisExercicios.user_tentativa[
        Number(this.$route.params.indiceStore) - 1
      ].fen.split(",");
    },
    avancaRetornaLance(sentido) {
      if (
        sentido == "proxima" &&
        this.contLance <= Number(this.fenDaTentativa.length) - 1
      ){
        this.contLance++;
      }
      else if (this.contLance > -2) {
        this.contLance--;
      } 

      this.theBoard.position(this.fenDaTentativa[this.contLance]);
      //
      if (this.contLance == -1 || this.contLance == -2) {
        this.recomecar()
      }


      for (let i = 0; i < this.tentativaPgn.length; i++) {
        document.getElementById(`jogada_${i}`).classList.remove('jogadaMark')
        document.getElementById(`jogada_${this.contLance}`).classList.add('jogadaMark')
      }
    },
    listaExerciciosAssiste() {
      this.showModal("modalNameModalListaExercicios");
    },
    goToAttempt(){
      if(this.especifiAttempt > 0 && this.especifiAttempt <= this.tentativas){
        let url = window.location.href.split('/')
        let urlLength = url.length
        url[urlLength - 1] = this.especifiAttempt
        location.replace(url.toString().replaceAll(',', '/'));
      }
    }
  },
};
</script>

<style scoped>
@media (max-width: 1087px) {
  
  .coluna-info {
    max-width: 250px;
    margin-left: 40px;
  }
}
@media (max-width: 1280px) {
  .stock-thinking
  {
    left: 30%;
    top: 20%;
    padding-left: 50px;
    margin-left: 5%;
  }
}
@media (max-width: 1328px)
{
  .stock-thinking
   {
     padding-left: 30px;
   }
}

@media (max-width: 1000px) {
  .stock-thinking {
    position: absolute;
    top: 180px;
    left: 120px;
  }
}

.icon-prev {
  margin-right: 10px;
}

.nav-prev-next .icon-next {
  margin-left: 10px;
}

h2 {
  font-size: 22px !important;
}

h3 {
  font-size: 16px !important;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px !important;
  font-weight: 300;
}

sup {
  font-size: 9px;
}

h6 {
  font-size: 12px;
}

/* .shape-historico-dica {
  height: 180px;
} */

.shape-historico-dica p {
  font-size: 12px;
}

.where-historico-de-jogadas-aluno {
  height: 75px;
}

.arrow-proxima-jogada {
  left: -19px;
  bottom: -15px;
}

.jogadas {
  max-height: 150px;
}

.shape {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.shape img {
  width: 18px;
}

.div-tabuleiro-img img {
  width: 100%;
}

.user_tentativa.lengths {
  font-size: 12px;
}

.small p {
  font-size: 10px;
}

button {
  z-index: 2 !important;
}

.shape-18-18 {
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
</style>
