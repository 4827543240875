<template>
  <div class="father">
    <lottie-player src="https://assets1.lottiefiles.com/datafiles/w8vBCvKwbZD7N0h/data.json" mode="bounce" background="transparent" speed="7" loop autoplay></lottie-player>
    <!-- <p class="color-fff px-4 py-2">StockFish está pensando...</p> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@900&display=swap");

.father {
  position: absolute;
  top: 42%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 200px; 
  height: 200px;
  margin-left: 1.5rem;
}

</style>
