var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"id":"modalModalListaExercicios","name":"modalNameModalListaExercicios","height":'auto',"width":'30%',"adaptive":true,"draggable":true},on:{"before-open":function($event){return _vm.verificaAoAbrir(_vm.exercicios)}}},[_c('div',{staticClass:"row p-0 bg position-relative",style:(("background-image:url('" + _vm.bgImage + "')"))},[_c('div',{staticClass:"position-absolute w-100 h-100 camada-dark"}),_c('div',{staticClass:"col-md-12 p-3"},[_c('div',{staticClass:"col-md-12 text-left"},[_c('h2',{staticClass:"color-fff pb-4"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"row"},_vm._l((_vm.exercicios),function(item,index){return _c('div',{key:("" + index),staticClass:"col-md-12"},[(_vm.validador)?_c('div',[(index == 0 && index < _vm.exercicios.length || _vm.getNextExercicio && index < _vm.exercicios.length && index <= (Number(_vm.$route.params.indiceExercicio)))?_c('button',{staticClass:"color-42cc7e",on:{"click":function($event){return _vm.verificaExistencia(item, index)}}},[_vm._v(" ("+_vm._s(item.cod)+")"+_vm._s(item.name)+" "+_vm._s(_vm.$route.path.match('continuar-exercicio') ? '' : item.exercicioUser.filter(function (arr) { return arr.user_id == _vm.getUserDatas.id && arr.status == 2; }).length > 0 ? '- Concluído' : '- Pendente')+" ")]):_c('button',{staticClass:"color-42cc7e",class:_vm.verificaQtdeTentativas(
                    _vm.exercicios[index == _vm.exercicios.length ? index : index - 1]
                  )
                    ? ''
                    : 'opacity-more',on:{"click":function($event){_vm.verificaQtdeTentativas(
                    _vm.exercicios[index == _vm.exercicios.length ? index : index - 1]
                  )
                    ? _vm.verificaExistencia(item, index)
                    : _vm.barraExercicio()}}},[_vm._v(" ("+_vm._s(item.cod)+") "+_vm._s(item.name)+" "+_vm._s(_vm.$route.path.match('continuar-exercicio') ? '' : item.exercicioUser.filter(function (arr) { return arr.user_id == _vm.getUserDatas.id && arr.status == 2; }).length > 0 ? '- Concluído' : '- Pendente')+" ")])]):_c('div',[_c('button',{class:("color-42cc7e " + (item.exercicioUser[0].tentativa > 0 || item.exercicioUser[0].status == 2 ? '' : 'opacity-more')),on:{"click":function($event){item.exercicioUser[0].tentativa >0 || item.exercicioUser[0].status == 2
                    ? _vm.routerPushAssistir(item)
                    : _vm.barraExercicio()}}},[_vm._v(" ("+_vm._s(item.cod)+") "+_vm._s(item.name)+" "+_vm._s(_vm.$route.path.match('continuar-exercicio') ? '' : item.exercicioUser.filter(function (arr) { return arr.user_id == _vm.$route.params.idAluno && arr.status == 2; }).length > 0 ? '- Concluído' : '- Pendente')+" ")])])])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }