<template>
  <div>
    <ModalPromovePeao @executaPromocao="executaPromocao" />
    <div style="text-align: center">
      <div id="board" style="width: 450px"></div>
      <!-- <div id="captured_pieces_w"></div><br><br>  -->
      <!-- <div id="captured_pieces_b"></div> -->
      <!-- <div id="fen"></div> -->
      <!-- <div id="status"></div> -->
      <!-- <div id="pgn"></div></div> -->
    </div>
  </div>
</template>

<script>
import ModalPromovePeao from "@/components/modais/ModalPromovePeao";
export default {
  data() {
    return {
      fenInicial: null,
      fenResolucao: [],
      bKfenResolucao: [],
      Tentativas: 0,
      theResolucao: [],
      Status: 1,
      pecaPromocao: null,
      theGame: null,
      theTarget: null,
      theTabuleiro: null,
      thePeca: [],
      TheFen: [],
      ThePgn: []
    };
  },
  name: "newboard",
  components: {
    ModalPromovePeao,
  },
  props: ["dadosExercicioAluno"],
  mounted() {
    this.theResolucao = this.dadosExercicioAluno.resolucao.split(",");
    this.$store.commit("SET_STATUS_ALUNO_PGN", false);
    this.verificaTentativas();
    this.preparaJogo();
    function captured_pieces(fen) {

      var new_fen = fen.split(" ");
      var new_fen = new_fen[0];

      // Anzahl wei�e Bauern

      var W_P = new_fen.split("P").length - 1;
      // Geschlagen wei�e Bauern
      var G_W_P = 8 - W_P;
      //G_W_P = 8;
      var g_w_bauer = "";
      for (var a = 0; a <= G_W_P - 1; a++) {
        var verschiebung = a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/wP.png" width="40" height="40"></div>';
        g_w_bauer = g_w_bauer + figur;
      }
      // Anzahl schwarze Bauern
      var b_p = new_fen.split("p").length - 1;
      // Geschlagen schwarze Bauern
      var g_b_p = 8 - b_p;
      //g_b_p = 8;
      var g_b_bauer = "";

      for (var a = 0; a <= g_b_p - 1; a++) {
        var verschiebung = a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/bP.png" width="40" height="40"></div>';
        g_b_bauer = g_b_bauer + figur;
      }
      // Anzahl wei�e Springer
      var W_N = new_fen.split("N").length - 1;
      // Geschlagen wei�e Springer
      var G_W_N = 2 - W_N;
      //G_W_N = 2;
      var G_W_Springer = "";
      for (var a = 0; a <= G_W_N - 1; a++) {
        var verschiebung = 130 + a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/wN.png" width="40" height="40"></div>';
        G_W_Springer = G_W_Springer + figur;
      }

      // Anzahl schwarze Springer
      var b_N = new_fen.split("n").length - 1;
      // Geschlagen schwarze Springer
      var G_b_N = 2 - b_N;
      //G_b_N = 2;
      var G_b_Springer = "";
      for (var a = 0; a <= G_b_N - 1; a++) {
        var verschiebung = 130 + a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/bN.png" width="40" height="40"></div>';
        G_b_Springer = G_b_Springer + figur;
      }

      // Anzahl wei�e L�ufer
      var W_B = new_fen.split("B").length - 1;
      // Geschlagen wei�e L�ufer
      var G_W_B = 2 - W_B;
      //G_W_B = 2;
      var G_W_Laeufer = "";
      for (var a = 0; a <= G_W_B - 1; a++) {
        var verschiebung = 170 + a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/wB.png" width="40" height="40"></div>';
        G_W_Laeufer = G_W_Laeufer + figur;
      }

      // Anzahl schwarze L�ufer
      var W_b = new_fen.split("b").length - 1;
      // Geschlagen schwarze L�ufer
      var G_W_b = 2 - W_b;
      //G_W_b = 2;
      var G_b_Laeufer = "";
      for (var a = 0; a <= G_W_b - 1; a++) {
        var verschiebung = 170 + a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/bB.png" width="40" height="40"></div>';
        G_b_Laeufer = G_b_Laeufer + figur;
      }

      // Anzahl wei�e T�rme
      var W_R = new_fen.split("R").length - 1;
      // Geschlagen wei�e T�rme
      var G_W_R = 2 - W_R;
      //G_W_R = 2;
      var G_W_Tuerme = "";
      for (var a = 0; a <= G_W_R - 1; a++) {
        var verschiebung = 210 + a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/wR.png" width="40" height="40"></div>';
        G_W_Tuerme = G_W_Tuerme + figur;
      }

      // Anzahl schwarze T�rme
      var b_R = new_fen.split("r").length - 1;
      // Geschlagen schwarze T�rme
      var G_b_R = 2 - b_R;
      //G_b_R = 2;
      var G_b_Tuerme = "";
      for (var a = 0; a <= G_b_R - 1; a++) {
        var verschiebung = 210 + a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/bR.png" width="40" height="40"></div>';
        G_b_Tuerme = G_b_Tuerme + figur;
      }

      // Anzahl wei�e Dame
      var W_Q = new_fen.split("Q").length - 1;
      // Geschlagen wei�e Dame
      var G_W_Q = 1 - W_Q;
      //G_W_Q = 1;
      var G_W_Dame = "";
      for (var a = 0; a <= G_W_Q - 1; a++) {
        var verschiebung = 250 + a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/wQ.png" width="40" height="40"></div>';
        G_W_Dame = G_W_Dame + figur;
      }

      // Anzahl schwarze Dame
      var W_q = new_fen.split("q").length - 1;
      // Geschlagen schwarze Dame
      var G_W_q = 1 - W_q;
      //G_b_q = 1;
      var G_b_Dame = "";
      for (var a = 0; a <= G_W_q - 1; a++) {
        var verschiebung = 250 + a * 15;
        var figur =
          '<div  style="position:absolute; left:' +
          verschiebung +
          'px;"> <img src="/novo/img/chesspieces/bQ.png" width="40" height="40"></div>';
        G_b_Dame = G_b_Dame + figur;
      }
      document.getElementById("captured_pieces_w").innerHTML =
        g_w_bauer + G_W_Springer + G_W_Laeufer + G_W_Tuerme + G_W_Dame;
      document.getElementById("captured_pieces_b").innerHTML =
        g_b_bauer + G_b_Springer + G_b_Laeufer + G_b_Tuerme + G_b_Dame;
    }

    var that = this;
    var peca = that.dadosExercicioAluno.PGN.split(",");
    this.thePeca = that.dadosExercicioAluno.PGN.split(",");

    var init = function() {
      var bkp = that.bKfenResolucao;
      var selfplay = false;
      //--- start example JS ---
      var board,
        game = new Chess(
          `${that.fenInicial} ${that.dadosExercicioAluno.cor[0]} KQkq - 0 1`
        ),
        statusEl = $("#status"),
        fenEl = $("#fen"),
        pgnEl = $("#pgn");

      // do not pick up pieces if the game is over
      // only pick up pieces for the side to move
      var onDragStart = function(source, piece, position, orientation) {
        if (
          game.game_over() === true ||
          (game.turn() === "w" && piece.search(/^b/) !== -1) ||
          (game.turn() === "b" && piece.search(/^w/) !== -1)
        ) {
          return false;
        }
      };

      // function makemove() {
      //   setRequest(game.fen());
      //   setTimeout(function () {
      //     ziehen(content);
      //   }, 1500);
      // }
      // function ziehen(zug) {
      //   var zug_bot = zug.split("-");
      //   var zug_von = zug_bot[0];
      //   var zug_nach = zug_bot[1];

      //   var possibleMoves = game.moves();

      //   // game over
      //   if (possibleMoves.length === 0) return;

      //   game.move({
      //     from: zug_von,
      //     to: zug_nach,
      //     promotion: "q", // NOTE: always promote to a queen for example simplicity
      //   });
      //   board.position(game.fen());
      //   updateStatus();
      //   document.getElementById("fen").innerHTML = game.fen();
      //   if (selfplay != false) {
      //     makemove();
      //   }
      // }

      var onDrop = function(source, target, piece, newPos, oldPos) {
        // see if the move is legal
        that.$store.commit("SET_TYPE_EXERCICIO", 2);
        var move = game.move({
          from: source,
          to: target,
          promotion: that.pecaPromocao, // NOTE: always promote to a queen for example simplicity
        });

        that.theTarget = target;

        if (
          (target == "h8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
          (target == "g8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
          (target == "f8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
          (target == "e8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
          (target == "d8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
          (target == "c8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
          (target == "b8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
          (target == "a8" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "white" ||
          (target == "h1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
          (target == "g1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
          (target == "f1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
          (target == "e1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
          (target == "d1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
          (target == "c1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
          (target == "b1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" ||
          (target == "a1" && piece.indexOf("P") > -1) && that.dadosExercicioAluno.cor == "black" 
        ) {
          that.moverPara = {
            from: source,
            to: target,
          };
          if (game.in_check() === false) {
            that.showModal("modalNamePromovePeao");
            that.theGame = game;
            setTimeout(() => {
              0;
              // segueJogadas()
            }, 1500);
          }
        } else {
          // segueJogadas()
        }

        // illegal move
        if (move === null) return "snapback";

        updateStatus();
        // makemove();

        setTimeout(() => {
          let objJogada = {
            peca: that.thePeca[0],
            fen: null,
            theBoard: board,
            turn: null,
            turnAluno: true,
            pecaLength: 0,
          };
          if (board.fen() == bkp[0]) {

            objJogada.turn = true;
            objJogada.peca = that.thePeca[0]
              ? that.thePeca[0]
              : "";
            if(that.thePeca[0] == 'b' || that.thePeca[0] == 'w'){
              that.thePeca[0].split('')[0].replace("w", "").replace("b", "").toString()              
            }
            objJogada.fen = that.theResolucao[0];
            that.theResolucao.splice(0, 1);
            // that.ThePgn.push(that.thePeca[0])
            that.thePeca.splice(0, 1);
            // that.ThePgn.push(that.thePeca[0])
            that.salvaExercicio(game.fen())

            that.$emit("criaBottaoJogada", objJogada);
            that.ThePgn.push(objJogada.peca)
            bkp.splice(0, 1);
            board.position(
              `${bkp[0]} ${that.dadosExercicioAluno.cor[0]} KQkq - 0 1`
            );
            game.load(
              `${bkp[0]} ${that.dadosExercicioAluno.cor[0]} KQkq - 0 1`
            );
            bkp.splice(0, 1);

            if (bkp.length == 0) {
              that.Status = 2;
              that.$store.commit("SET_STATUS_ALUNO_PGN", true);
              that.showModal("modalNameStatusExercicio");
            }

            objJogada.turn = that.dadosExercicioAluno.cor[0] == "w" ? "b" : "w";
            objJogada.peca = that.thePeca[0]
              ? that.thePeca[0]
              : "";
            if(that.thePeca[0] == 'b' || that.thePeca[0] == 'w'){
              that.thePeca[0].split('')[0].replace("w", "").replace("b", "").toString()              
            }
            // that.thePeca.splice(0, 1);
            objJogada.pecaLength = peca.length;
            setTimeout(() => {
              objJogada.fen = that.theResolucao[0];
              that.theResolucao.splice(0, 1);
              // that.ThePgn.push(that.thePeca[0])
              that.thePeca.splice(0, 1);
              objJogada.turnAluno = false;
              that.$emit("criaBottaoJogada", objJogada);
              that.ThePgn.push(objJogada.peca)
              that.salvaExercicio(game.fen())
            }, 250);
            // board.move('d1-d5')

            if (objJogada.pecaLength == 0) {
              that.Status = 2;
              that.$store.commit("SET_STATUS_ALUNO_PGN", true);
              that.showModal("modalNameStatusExercicio");
              // that.verificaTentativas(true);
            }
          } else {
            let a = game.pgn().split("]");
            console.log(a)
            let b = a.length > 1 ? a[2].split(".") : a[0].split(".");

            for (let i = 0; i < b.length; i++) {
              let c = b[i].split(" ");
              if (c[1] != undefined) {
                that.ThePgn.push(c[1])
              }
            }
            // that.verificaTentativas();
            setTimeout(() => {
              that.salvaExercicio(game.fen());
              that.showModal("modalNameStatusExercicio");
            }, 500);

            // função que remove os botões das jogadas e contador da planilha

            // let elementsToRemoveInDOM = document.getElementsByClassName(
            //   "jogada"
            // );
            // let elementsToRemoveInDOMContador = document.getElementsByClassName(
            //   "contador-jogadas"
            // );
            // while (elementsToRemoveInDOM[0]) {
            //   elementsToRemoveInDOM[0].parentNode.removeChild(
            //     elementsToRemoveInDOM[0]
            //   );
            //   elementsToRemoveInDOMContador[0].parentNode.removeChild(
            //     elementsToRemoveInDOMContador[0]
            //   );
            // }

            bkp = that.fenResolucao;
            // board.position(
            //   `${that.fenInicial} ${that.dadosExercicioAluno.cor[0]} KQkq - 0 1`
            // );
            // game.load(
            //   `${that.fenInicial} ${that.dadosExercicioAluno.cor[0]} KQkq - 0 1`
            // );
            var objDiv = document.getElementById("thePlanilha");
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        }, 250);
      };

      // update the board position after the piece snap
      // for castling, en passant, pawn promotion
      var onSnapEnd = function() {
        board.position(game.fen());
      };

      var updateStatus = function() {
        var status = "";

        var moveColor = "White";
        if (game.turn() === "b") {
          moveColor = "Black";
        }

        // checkmate?
        if (game.in_checkmate() === true) {
          status = "Game over, " + moveColor + " is in checkmate.";
        }

        // draw?
        else if (game.in_draw() === true) {
          status = "Game over, drawn position";
        }

        // game still on
        else {
          status = moveColor + " to move";

          // check?
          if (game.in_check() === true) {
            status += ", " + moveColor + " is in check";
          }
        }
        // if(s != false){sound();}
        statusEl.html(status);
        fenEl.html(game.fen());
        pgnEl.html(game.pgn());
        //captured_pieces(game.fen());
      };
      var cfg = {
        draggable: true,
        position: `${that.fenInicial} ${that.dadosExercicioAluno.cor[0]} KQkq - 0 1`,
        onDragStart: onDragStart,
        onDrop: onDrop,
        onSnapEnd: onSnapEnd,
        orientation: that.dadosExercicioAluno.cor,
      };
      board = new ChessBoard("board", cfg);
      that.theTabuleiro = board;

      updateStatus();
      $("#flipOrientationBtn").on("click", board.flip);
      $("#whiteOrientationBtn").on("click", function() {
        board.orientation("white");
      });

      $("#blackOrientationBtn").on("click", function() {
        board.orientation("black");
      });

      $("#move").on("click", function() {
        setRequest(game.fen());
        setTimeout(function() {
          ziehen(content);
        }, 2000);
      });

      //--- end example JS ---
    }; // end init()
    $(document).ready(init);

    // function sound(){
    //     document.getElementById('sound').innerHTML = '<audio autoplay preload controls> <source src="sound/move.wav" type="audio/wav" /> </audio>';
    // }
    var s = "true";
    // function soundcheck() {
    //     s = document.getElementById("soundcheck").checked;
    // }
    function stockfcheck() {
      selfplay = document.getElementById("stockfcheck").checked;
      //if(selfplay != false){makemove();}
      //document.getElementById('test').innerHTML = selfplay;
    }
  },
  methods: {
    preparaJogo() {
      let fen = this.dadosExercicioAluno.resolucao.split(",");
      this.fenInicial = fen[0];
      fen.splice(0, 1);
      this.bKfenResolucao = fen;
      this.fenResolucao = fen;
    },
    // atualizaDadosExercicio(objAtualizar) {

    //   $.ajax({
    //     type: "PUT",
    //     url: `${this.VUE_APP_CMS}api/exercicioUser/${this.$route.params.idExercicio}`,
    //     data: objAtualizar,
    //     dataType: "json",
    //     headers: {
    //       Authorization: this.$store.getters.getPerfilToken,
    //     },
    //     success: (response) => {
    //       this.$toast.open({
    //         message: `Dados Exercício salvo com sucesso`,
    //         type: "success",
    //         duration: 2500,
    //         dismissible: true,
    //         position: "bottom-right"
    //       });
    //     },
    //     error: (response) => {
    //       this.$toast.open({
    //         message: `Algo de errado ocorreu e seu jogo não será salvo.`,
    //         type: "error",
    //         duration: 2500,
    //         dismissible: true,
    //         position: "bottom-right"
    //       });
    //     },
    //   });
    // },
    teste() {},
    executaPromocao(val) {
      var peca = this.dadosExercicioAluno.PGN.split(",");
      if (this.$route.path != "/login") {
        let tabuleiroPosicao = this.theTabuleiro.position();

        this.hideModal("modalNamePromovePeao");

        setTimeout(() => {
          if (tabuleiroPosicao.a7 && this.theTarget == "a8") {
            delete tabuleiroPosicao.a7;
            tabuleiroPosicao.a8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.b7 && this.theTarget == "b8") {
            delete tabuleiroPosicao.b7;
            tabuleiroPosicao.b8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.c7 && this.theTarget == "c8") {
            delete tabuleiroPosicao.c7;
            tabuleiroPosicao.c8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.d7 && this.theTarget == "d8") {
            delete tabuleiroPosicao.d7;
            tabuleiroPosicao.d8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.e7 && this.theTarget == "e8") {
            delete tabuleiroPosicao.e7;
            tabuleiroPosicao.e8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.f7 && this.theTarget == "f8") {
            delete tabuleiroPosicao.f7;
            tabuleiroPosicao.f8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.g7 && this.theTarget == "g8") {
            delete tabuleiroPosicao.g7;
            tabuleiroPosicao.g8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.h7 && this.theTarget == "h8") {
            delete tabuleiroPosicao.h7;
            tabuleiroPosicao.h8 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.a2 && this.theTarget == "a1") {
            delete tabuleiroPosicao.a2;
            tabuleiroPosicao.a1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.b2 && this.theTarget == "b1") {
            delete tabuleiroPosicao.b2;
            tabuleiroPosicao.b1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.c2 && this.theTarget == "c1") {
            delete tabuleiroPosicao.c2;
            tabuleiroPosicao.c1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.d2 && this.theTarget == "d1") {
            delete tabuleiroPosicao.d2;
            tabuleiroPosicao.d1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.e2 && this.theTarget == "e1") {
            delete tabuleiroPosicao.e2;
            tabuleiroPosicao.e1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.f2 && this.theTarget == "f1") {
            delete tabuleiroPosicao.f2;
            tabuleiroPosicao.f1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.g2 && this.theTarget == "g1") {
            delete tabuleiroPosicao.g2;
            tabuleiroPosicao.g1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          if (tabuleiroPosicao.h2 && this.theTarget == "h1") {
            delete tabuleiroPosicao.h2;
            tabuleiroPosicao.h1 = `${
              this.dadosExercicioAluno.cor[0]
            }${val.toUpperCase()}`;
          }
          this.pecaPromocao = `${val}`;
          this.theTabuleiro.position(tabuleiroPosicao);
          // this.theTabuleiro.position(this.theTabuleiro.)
          this.theTabuleiro.position(this.theTabuleiro.fen());
          this.pecaPromocao = null;
        }, 200);
        setTimeout(() => {
          this.theTabuleiro.move(`${this.moverPara.from}-${this.moverPara.to}`);
          setTimeout(() => {
            let objJogada = {
              peca: this.thePeca[0],
              fen: null,
              theBoard: this.theTabuleiro,
              turn: null,
              turnAluno: true,
              pecaLength: 0,
            };

            var splitFen = this.theTabuleiro.fen().split('/')
            splitFen[0] = splitFen[0].replace("P", val.toUpperCase())
            this.theTabuleiro.position(splitFen.join('/'))
            if (this.theTabuleiro.fen() == this.bKfenResolucao[0]) {
              objJogada.turn = true;
              objJogada.peca = this.thePeca[0]
                ? this.thePeca[0]
                : "";
              if(this.thePeca[0]== 'b' || this.thePeca[0]== 'w'){
                this.thePeca[0].split('')[0].replace("w", "").replace("b", "").toString()              
              }
              objJogada.fen = this.theResolucao[0];
              this.ThePgn.push(this.thePeca[0])
              this.theResolucao.splice(0, 1);
              this.salvaExercicio(this.bKfenResolucao[0]);
              this.$emit("criaBottaoJogada", objJogada);
              // this.thePeca.splice(0, 1);
              this.bKfenResolucao.splice(0, 1);
              this.theTabuleiro.position(
                `${this.bKfenResolucao[0]} ${this.dadosExercicioAluno.cor[0]} KQkq - 0 1`
              );
              this.theGame.load(
                `${this.bKfenResolucao[0]} ${this.dadosExercicioAluno.cor[0]} KQkq - 0 1`
              );
              this.bKfenResolucao.splice(0, 1);

              objJogada.turn =
                this.dadosExercicioAluno.cor[0] == "w" ? "b" : "w";

              objJogada.pecaLength = this.thePeca.length;
              setTimeout(() => {
                objJogada.peca = this.thePeca[1]
                  ? this.thePeca[1]
                  : "";
                if(this.thePeca[1] == 'b' || this.thePeca[1] == 'w'){
                  this.thePeca[1].split('')[0].replace("w", "").replace("b", "").toString()              
                }
                objJogada.fen = this.theResolucao[0];
                this.theResolucao.splice(0, 1);
                this.thePeca.splice(0, 2);
                this.ThePgn.push(this.thePeca[1])
                objJogada.turnAluno = false;
                this.$emit("criaBottaoJogada", objJogada);
              }, 250);
              // this.theTabuleiro.move('d1-d5')
              
              if (objJogada.pecaLength == 0 || objJogada.pecaLength == 1) {
                this.Status = 2;
                this.$store.commit("SET_STATUS_ALUNO_PGN", true);
                this.showModal("modalNameStatusExercicio");
                this.verificaTentativas(true);
              }
            } else {
              // this.verificaTentativas();
              setTimeout(() => {
                this.salvaExercicio(this.theGame.fen());
                this.showModal("modalNameStatusExercicio");
              }, 500);

              let elementsToRemoveInDOM = document.getElementsByClassName(
                "jogada"
              );
              while (elementsToRemoveInDOM[0]) {
                elementsToRemoveInDOM[0].parentNode.removeChild(
                  elementsToRemoveInDOM[0]
                );
              }

              this.bKfenResolucao = this.fenResolucao;
              this.theTabuleiro.position(
                `${this.fenInicial} ${this.dadosExercicioAluno.cor[0]} KQkq - 0 1`
              );
              this.theGame.load(
                `${this.fenInicial} ${this.dadosExercicioAluno.cor[0]} KQkq - 0 1`
              );
            }
          }, 250);
        }, 230);
      }
    },
    salvarQuantidadeExerciciosPendentes(){
      this.$store.commit('SET_EXERCICIO_PENDENTE', Number(
        this.$store.getters.getDadosExercicioPaginacao
        .map(param => param.exercicioUser
        .filter(param => param.status == 2 && param.user_id == this.$store.getters.getUserDatas.id))
        .map(param => param.length)
        .filter(param => param === 0).length
      )
    )
    },
    salvaExercicio(theFen) {
      this.TheFen.push(theFen)
      let objCriar = {
        exercicio_id: this.$route.params.idExercicio,
        user_id: this.$store.getters.getUserDatas.id,
        status: this.Status,
        tentativa: Number(this.Tentativas),
        // tentativa: Number(this.Tentativas) + 1,
        fen: this.TheFen.toString(),
        pgn: this.ThePgn.toString()
      };

      $.ajax({
        type: "PUT",
        url: `${this.VUE_APP_CMS}api/exercicioUser/${this.$route.params.temaGrupoExercicio}`,
        data: objCriar,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          // this.$store
          //   .dispatch("getTemas", {
          //     token: this.$store.getters.getPerfilToken,
          //     page: 1,
          //   })
          //   .then((resolve) => {
          //       if (resolve.valid) {
          //         this.salvarQuantidadeExerciciosPendentes()
          //       }
          //   });
            // this.$toast.open({
            //    message: `Jogada salva com sucesso`,
            //   type: "success",
            //   duration: 2500,
            //   dismissible: true,
            //   position: "bottom-right",
            // });
        },
        error: (response) => {
          this.toastGlobal(
            "error",
            "Algo de errado ocorreu e seu jogo não será salvo."
          );
        },
      });
    },
    verificaTentativas(statusSuccess = false) {
      let objAtualizar = {
        status: null,
        tentativa: null,
      };
      $.ajax({
        type: "GET",
        url: `${this.VUE_APP_CMS}api/exercicioUser/${this.$route.params.temaGrupoExercicio}`,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.$store.commit("SET_TENTATIVAS_EXERCICIOS", response.tentativa);
          this.$store.commit("SET_EXERCICIO_CONCLUIDO", response.status);
          this.Tentativas = Number(response.tentativa) + 1;
          this.$store.commit("SET_NEXT_EXERCICIO", true);
          // if (response.tentativa < 3 || statusSuccess) {
            this.Status = response.status === 2 ? response.status : 1
          if (statusSuccess) {
            // objAtualizar.status = 2;
            // this.Status = 2;
            objAtualizar.tentativa = response.tentativa + 1;
            // this.atualizaDadosExercicio(objAtualizar);
            this.$emit("verificaTentativas", true);
            this.$store.commit("SET_NEXT_EXERCICIO", true);
          } else if (response.tentativa >= 2) {
            // this.Status = 2;
            // objAtualizar.status = 2;
            this.$emit("verificaTentativas", true);
            objAtualizar.tentativa = response.tentativa + 1;
            // this.atualizaDadosExercicio(objAtualizar);
            this.$store.commit("SET_NEXT_EXERCICIO", true);
          }else {
            objAtualizar.status = 1;
            // this.Status = 1;
          }
        },
        error: (response) => {
        },
      });
    },
  },
};
</script>

<style scoped>
@import "../../public/css/chessboard-1.0.0.min.css";
</style>
