<template>
  <div>
    <div class="mt-5">
      <div id="myBoard" style="width: 450px"></div>
      <!-- <div id="captured_pieces_w"></div><br><br> 
      <div id="captured_pieces_b"></div>
      <div id="fen"></div>
      <div id="status"></div>
      <div id="pgn"></div></div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      theBoard: null,
    };
  },
  props: ["dadosExercicioAluno"],
  name: "newboard",
  components: {},
  mounted() {
    setTimeout(() => {
      var config = {
        position: `${this.dadosExercicioAluno.fen} ${this.dadosExercicioAluno.cor[0]} - - 0 1`,
        orientation: `${this.dadosExercicioAluno.cor}`,
      };
      this.theBoard = new ChessBoard("myBoard", config);
    }, 500);

    this.recebeJogadas();
  },

  methods: {
    recebeJogadas() {
      $.ajax({
        type: "GET",
        url: `${this.VUE_APP_CMS}api/exercicioUser/${this.$route.params.temaGrupoExercicio}`,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          setTimeout(() => {
            console.log("the board", this.theBoard)
            console.log("this.dadosExercicioAluno.fen", this.dadosExercicioAluno.fen)
            console.log("dados", response)
            this.$emit("tranfereDadosDasTentativas", {
              mainBoard: this.theBoard,
              mainFEN: this.dadosExercicioAluno.fen,
              dados: response,
            });
          }, 550);
        },
        error: (response) => {
          this.toastGlobal(
            "error",
            "Algo de errado ocorreu e você não verá os dados corretamente."
          );
        },
      });
    },
  },
};
</script>

<style scoped>
@import "../../public/css/chessboard-1.0.0.min.css";
</style>
