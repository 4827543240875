<template>
  <modal
    id="modalStatusExercicio"
    name="modalNameStatusExercicio"
    :height="'auto'"
    :width="'30%'"
    :adaptive="true"
    :draggable="true"
    @closed="tentarNovamente()"
    @opened="stopLoader()"
  >
    <div
      class="row p-0 bg position-relative"
      :style="`background-image:url('${bgImage}')`"
    >
      <div
        :class="
          `position-absolute ${
            $store.getters.getStatusAlunoPGN ? 'camada-light' : 'camada-dark'
          } w-100 h-100`
        "
      ></div>
      <div class="col-md-12 py-3">
        <div class="col-md-12">
          <h2 class="color-fff">
            {{
              $store.getters.getSituacaoGameStock == 'vitoria'
              ? "Excelente, você venceu!"
              : $store.getters.getSituacaoGameStock == 'empate' 
              ? "Quase! A partida empatou."
              : $store.getters.getSituacaoGameStock == 'derrota' 
              ? "O Skaki venceu."
              : $store.getters.getStatusAlunoPGN
              ? "Exercício concluído!"
              : "Há um lance melhor"
            }}
          </h2>
          <h3 class="py-4 color-fff">
            {{
              $store.getters.getStatusAlunoPGN
              ? "Parabéns!"
              : $store.getters.getSituacaoGameStock == 'empate' 
              ? "Continue tentando."
              : $store.getters.getSituacaoGameStock == 'derrota'
              ? "Tente uma ideia diferente."
              : "Você Consegue!"
            }}
          </h3>
        </div>
        <div class="col-md-12">
          <button
            v-if="!$store.getters.getStatusAlunoPGN && this.getExercicioPendente > 0"
            @click="tentarNovamente"
            class="mr-3 btn-style-default-less bg-0e5caf color-fff"
          >
            Realizar novamente
          </button>
          <button
            v-else
            :class="`mr-2 ${this.verificacaoBotaoDeListaDeExercicios() > 0 ? '' : 'opacity-more'}`"
            class="btn-style-default-less bg-42cc7e color-fff"
            @click="showModal('modalNameModalListaExercicios')"
          >
            Lista de exercícios
          </button>
           <router-link
            v-if="this.getExercicioPendente === 0"
            class="btn-style-default-less bg-42cc7e color-fff"
            :to="'/aluno/inicial'"
          >
            Tela Inicial
          </router-link>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getDadosExercicioPaginacao", "getStatusAlunoPGN", "getUserDatas", "getExercicioPendente"]),
  },
  data() {
    return {
      bgImage: require("../../assets/bg/piaoRei.png"),
      proxExercicios: this.getTemasExercicios,
      exercicioNext: this.$route.params.indiceStore,
      dadosOficiaisExercicios: null,
      atualComponente: null,
      codigoFenExercicioAluno: null,
      dadosExercicios: null,
      getPaginacao: this.$store.getters.getDadosExercicioPaginacao,
      verificaPaginacao: this.$route.params.indiceStore == 0 ? true : false,
    };
  },
  props: ["statusJogada", "exercicios"],
  created() {
    this.verificacaoBotaoDeListaDeExercicios()
  },
  methods: {
    resetarStorePGN() {
      this.$store.commit("SET_STATUS_ALUNO_PGN", false);
    },
    tentarNovamente() {
      this.hideModal("modalNameStatusExercicio");
      this.resetarStorePGN();

      let elementsToRemoveInDOM = document.getElementsByClassName("jogada");
      while (elementsToRemoveInDOM[0]) {
        elementsToRemoveInDOM[0].parentNode.removeChild(
          elementsToRemoveInDOM[0]
        );
      }
      location.reload();
    },
    pularExercicio() {
      this.resetarStorePGN();
    },
    valida(val) {
      if (val == "proximo" && this.dadosOficiaisExercicios)
        return (
          this.dadosOficiaisExercicios.user_tentativa.length ==
          this.$route.params.indiceStore
        );
      else return this.$route.params.indiceStore < 2;
    },
    verificacaoBotaoDeListaDeExercicios() {
        return this.$store.getters.getDadosExercicioPaginacao
        .filter(param => param.id == this.$route.params.idExercicio)
        .map(param => param.exercicioUser
        .filter(param => param.user_id == this.$store.getters.getUserDatas.id && param.status === 2)).length
    },
    nextExercicios(sentido) {
      let count = Number(this.$route.params.indiceStore);
      let idExercicio = null;
      if (this.getStatusAlunoPGN === true) {
        // count += 1;
        // this.$router.push(
        //   `/aluno/continuar-exercicio/${
        //     this.$route.params.temaGrupoExercicio
        //   }/${this.$route.params.temaExercicio}/${
        //     this.getPaginacao[count - 1].id
        //   }/${count}`
        // );

        // location.reload();
        this.showModal("modalNameModalListaExercicios");
      } else {
        this.toastGlobal(
          "error",
          "Desculpe, Você ainda não permissão para fazer o proxímo exercício"
        );
      }
    },
    stopLoader(){
      setTimeout(() => {
        this.$store.commit('SET_BLOQUEAR_TABULEIRO', false)
      }, 1000);
    }
  },
};
</script>

<style scoped>
#modalStatusExercicio {
  z-index: 10000;
}
h2 {
  font-size: 26px;
}
h3 {
  font-size: 20px;
}

.bg {
  background-size: cover;
}

.camada-dark {
  background-color: rgba(0, 0, 0, 0.8);
}

.camada-light {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
