export default{
    data() {
        return {
            coordenadaPecaProfessor: [],
            coordenadaPecaAluno: [],
            pecaAluno: 'black',
        }
    },
    methods: {
        recebeCoordenadaPeca(data) {
            let dataToAdd = data.data.history.pop()
            let quemAcrescentaJogada = data.quemJogou == 'professor' ? 'coordenadaPecaAluno' : 'coordenadaPecaProfessor'
            let paraVerificar = data.quemJogou == 'professor' ? data.quemJogou : 'aluno'
            let pecaVerificaImagem = dataToAdd.length <= 2 ? null : dataToAdd.toLowerCase().split('')
            let templateToAddJogada = `
                <div class="d-flex justify-content-between">
                    <div>
                        <h4 class="coord">${this.verificarLengthCoords(paraVerificar)}</h4>
                    </div>
                    <div class="pl-2">
                        <h4 class="coord">
                            <img src="${this.verifyImageToChoord(pecaVerificaImagem == null ? pecaVerificaImagem : pecaVerificaImagem[0], data.data.turn)}" alt="">                            
                            ${dataToAdd}
                        </h4>
                    </div>
                </div>
            `
            if (quemAcrescentaJogada == 'coordenadaPecaAluno') {
                this.coordenadaPecaProfessor.push(dataToAdd)
                $(`#coordenadaPecaProfessor`).append(templateToAddJogada)
            } else if (quemAcrescentaJogada == 'coordenadaPecaProfessor') {
                this.coordenadaPecaAluno.push(dataToAdd)
                $(`#coordenadaPecaAluno`).append(templateToAddJogada)
            }
        },
        verificarLengthCoords(whoPlay) {
            let valorRetorno = 0
            switch (whoPlay) {
                case 'professor':
                    valorRetorno = this.coordenadaPecaProfessor.length + 1
                    break;

                case 'aluno':
                    valorRetorno = this.coordenadaPecaAluno.length + 1
                    break;

                default:
                    break;

            }
            return valorRetorno = valorRetorno >= 10 ? valorRetorno : `0${valorRetorno}`
        },
    },
}