<template>
  <modal
    v-if="$route.path.match('aluno')"
    id="modalmodalBemVindo"
    name="modalNamemodalBemVindo"
    :height="'auto'"
    :width="'25%'"
    :adaptive="true"
    :draggable="true"
  >
    <div class="text-center py-4 px-3">
      <h3 class="title f-w-700">Bem-vindo ao Tema:</h3>
      <h2 class="my-3">{{ propTitleTema }}</h2>
      <button
        @click="hideModal('modalNamemodalBemVindo')"
        class="btn-style-default bg-0e5daf color-fff"
      >
        Vamos Lá
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      initialSound: require("@/assets/audio/inicouIntenso.wav"),
    };
  },
  props: ["propTitleTema", "play"],
  created() {
    // if (this.play && this.$route.path.match("meus-temas/2"))
      // this.playSong(this.initialSound);
  },
};
</script>

<style scoped>
h2 {
  font-size: 18px;
  font-weight: 400;
}

h3 {
  font-size: 14px;
}
</style>
