<template>
  <modal
    id="modalModalListaExercicios"
    name="modalNameModalListaExercicios"
    :height="'auto'"
    :width="'30%'"
    :adaptive="true"
    :draggable="true"
    @before-open="verificaAoAbrir(exercicios)"
  >
    <div
      class="row p-0 bg position-relative"
      :style="`background-image:url('${bgImage}')`"
    >
      <div class="position-absolute w-100 h-100 camada-dark"></div>
      <div class="col-md-12 p-3">
        <div class="col-md-12 text-left">
          <h2 class="color-fff pb-4">{{ label }}</h2>
          <div class="row">
            <div
              class="col-md-12"
              v-for="(item, index) in exercicios"
              :key="`${index}`"
            >
              <div v-if="validador">
                <button
                  class="color-42cc7e"
                  @click="verificaExistencia(item, index)"
                  v-if="index == 0 && index < exercicios.length || getNextExercicio && index < exercicios.length && index <= (Number($route.params.indiceExercicio))"
                >
                  ({{ item.cod }}){{ item.name }} {{ $route.path.match('continuar-exercicio') ? '' : item.exercicioUser.filter(arr => arr.user_id == getUserDatas.id && arr.status == 2).length > 0 ? '- Concluído' : '- Pendente'}}
                </button>
                <button
                  class="color-42cc7e"
                  v-else
                  @click="
                    verificaQtdeTentativas(
                      exercicios[index == exercicios.length ? index : index - 1]
                    )
                      ? verificaExistencia(item, index)
                      : barraExercicio()
                  "
                  :class="
                    verificaQtdeTentativas(
                      exercicios[index == exercicios.length ? index : index - 1]
                    )
                      ? ''
                      : 'opacity-more'
                  "
                >
                  ({{ item.cod }}) {{ item.name }} {{ $route.path.match('continuar-exercicio') ? '' : item.exercicioUser.filter(arr => arr.user_id == getUserDatas.id && arr.status == 2).length > 0 ? '- Concluído' : '- Pendente'}}
                </button>
              </div>
              <div v-else>
                <button
                  :class="
                    `color-42cc7e ${
                      item.exercicioUser[0].tentativa > 0 || item.exercicioUser[0].status == 2 ? '' : 'opacity-more'
                    }`
                  "
                  @click="
                    item.exercicioUser[0].tentativa >0 || item.exercicioUser[0].status == 2
                      ? routerPushAssistir(item)
                      : barraExercicio()
                  "
                >
                  ({{ item.cod }}) {{ item.name }} 
                  {{ $route.path.match('continuar-exercicio') ? '' : item.exercicioUser.filter(arr => arr.user_id == $route.params.idAluno && arr.status == 2).length > 0 ? '- Concluído' : '- Pendente'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      bgImage: require("../../assets/bg/piaoRei.png"),
      dadosGetExerciciouser: [],
      validador:
        this.$store.getters.getUserDatas.profile_id == 5 ? true : false,
      theRouter: this.$route.params,
    };
  },
  computed: {
    ...mapGetters(["getUserDatas", "getDadosExercicioPaginacao", "getNextExercicio"]),
  },
  props: ["label", "exercicios"],
  methods: {
    verificaAoAbrir(val) {
      if (this.$route.path == '/aluno/inicial') {
        this.$store.commit("SET_DATA_EXERCICIOS_PAGINACAO", this.exercicios);
        setTimeout(() => {
          this.$store.commit("SET_DATA_EXERCICIOS_PAGINACAO", this.exercicios);
        }, 250);
      }else{
        // this.exercicios = this.getDadosExercicioPaginacao
      }
      if (this.validador) {
        val.forEach((element) => {
          if (element.exercicioUser.length <= 0) {
            //this.criarDB(element.id);
            this.$nextTick(() => {
              $.ajax({
                type: "GET",
                url: `${this.VUE_APP_CMS}api/exercicioUser`,
                dataType: "json",
                headers: {
                  Authorization: this.$store.getters.getPerfilToken,
                },
                success: (response) => {
                  this.dadosGetExerciciouser = response.data;
                  this.$router.push(
                    `/aluno/continuar-exercicio/${val.exercicioUser[0].id}/${val.tema_id}/${val.id}/1`
                  );
                },
                error: (response) => {
                  this.toastGlobal(
                    "error",
                    `Algo de errado ocorreu e seu jogo não será salvo`
                  );
                },
              });
            });
          }
        });
      }
    },
    verificaExistencia(item, index) {      
      if (this.validador) {
        if (item.exercicioUser[0].id) {
          this.$router.push(
            `/aluno/continuar-exercicio/${item.exercicioUser[0].id}/${item.tema_id}/${item.id}/1/${index+1}`
          );
        }
        if(this.$route.path.match('/aluno/continuar-exercicio/')){
          setTimeout(() => {
            location.reload()
          }, 50);
        }
      }
    },
    criarDB(val) {
      let objCriar = {
        exercicio_id: val,
        user_id: this.$store.getters.getUserDatas.id,
        status: 1,
        tentativa: 0,
        PGN: "vazio",
      };
      $.ajax({
        type: "POST",
        url: `${this.VUE_APP_CMS}api/exercicioUser`,
        data: objCriar,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.$emit("atualizaDados");
          this.toastGlobal(
            "success",
            `Banco de dados do Exercício criado com sucesso`
          );
        },
        error: (response) => {
          this.toastGlobal(
            "error",
            `Algo de errado ocorreu e seu jogo não será salvo.`
          );
        },
      });
    },
    barraExercicio() {
      let text = this.validador
        ? `Você ainda não possui permissão para realizar esse exercício! \r `
        : `O aluno ainda não tentou realizar este exercício \r `;
      this.toastGlobal("error", text);
    },
    verificaQtdeTentativas(val) {
      if (this.validador) {
        let feedBack = val ? val.exercicioUser[0].tentativa : false;

        if ((feedBack && feedBack >= 3) || val.exercicioUser[0].status == 2) {
          return true;
        } else {
          return false;
        }
      }
    },
    routerPushAssistir(val) {
      if (val.exercicioUser.length > 0) {
        this.$router.push(
          `/${this.verifyStatus(
            this.getUserDatas.profile_id
          )}/unidades-e-instituicoes/${this.theRouter.idunidade}/${
            this.theRouter.unidade
          }/${this.theRouter.idclass}/${this.theRouter.class}/statusAluno/${
            this.theRouter.idAluno
          }/${this.theRouter.nameAluno}/${val.exercicioUser[0].id}/${
            val.tema_id
          }/${val.id}/1`
        );
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.toastGlobal(
          "error",
          "O aluno em questão ainda não tentou esse exercício"
        );
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 22px;
}

a {
  font-size: 14px;
}

a:hover {
  color: #fff;
  font-weight: 700;
}

.bg {
  background-size: cover;
}

.camada-dark {
  background-color: rgba(0, 0, 0, 0.8);
}

button {
  border: none;
  background-color: transparent;
  text-align: left;
}
</style>
